import "./App.css";
import Game from "./Game";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import MobileAd from "./MobileAd";
import axios from "axios";




function App() {
  const maxGuesses = 6;
  return (
    <div className={"App-container"}>
      <Game maxGuesses={maxGuesses}/>
      <div className="mobile-bottom">
        <MobileAd />
      </div>
    </div>
  )
    
}
export default App;
