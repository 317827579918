import React, { useState, useEffect } from "react";
import axios from "axios";


export default function Test() {
  const [values, setValues] = useState({
    Result: ""
  });

  const data = axios.create({
    baseURL: "https://api.wordplay.com/api/user/check"
  });

  useEffect(() => {
    data
      .get(`https://api.wordplay.com/api/user/check`)
      .then((res) => {
        console.log(res.data);

        setValues({
          Result: res.data.result,
        });
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  }, []);

  return (
    <div>
    </div>
  );
}
