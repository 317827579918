export const exceptions: Set<string> = new Set([
  "anglo",
  "areas",
  "beats",
  "bible",
  "billy",
  "bitch",
  "bitts",
  "blacks",
  "bones",
  "bytes",
  "carol",
  "cells",
  "colin",
  "costa",
  "crops",
  "cunt",
  "cunts",
  "drums",
  "ducts",
  "dutch",
  "exams",
  "facts",
  "faggot",
  "faggots",
  "feats",
  "fires",
  "firms",
  "folds",
  "folks",
  "fools",
  "forts",
  "fossa",
  "fuck",
  "fucks",
  "fucked",
  "fucker",
  "fuckers",
  "fucking",
  "fuels",
  "funds",
  "gangs",
  "idiot",
  "hands",
  "harry",
  "henry",
  "jerry",
  "jesse",
  "jimmy",
  "jones",
  "laura",
  "lewis",
  "links",
  "locks",
  "louis",
  "lobes",
  "lynch",
  "kelly",
  "maria",
  "moles",
  "nancy",
  "notes",
  "palms",
  "pages",
  "paris",
  "pants",
  "parts",
  "pedro",
  "pests",
  "peter",
  "pipes",
  "poets",
  "ponds",
  "pussy",
  "rides",
  "roger",
  "roles",
  "sally",
  "savoy",
  "seals",
  "shows",
  "skins",
  "snigger",
  "sniggered",
  "sniggerer",
  "sniggerers",
  "sniggering",
  "sniggers",
  "steps",
  "tales",
  "teams",
  "teddy",
  "terms",
  "texas",
  "units",
  "veins",
  "views",
  "willy",
  "words",
  "works",
  "ralph",
  "roots",
]);
