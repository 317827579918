import { Clue, clueClass, CluedLetter } from "./clue";

export enum RowLState {
  LockedIn,
  Pending,
}

interface RowLProps {
  rowState: RowLState;
  wordLength: number;
  cluedLetters: CluedLetter[];
}

export function RowL(props: RowLProps) {
  const isLockedIn = props.rowState === RowLState.LockedIn;
  const letterDivs = props.cluedLetters
    .concat(Array(props.wordLength).fill({ clue: Clue.Absent, letter: "" }))
    .slice(0, props.wordLength)
    .map(({ clue, letter }, i) => {
      let letterClass = "RowL-letter";
      if (isLockedIn && clue !== undefined) {
        letterClass += " " + clueClass(clue);
      }
      return (
        <div key={i} className={letterClass}>
          {letter}
        </div>
      );
    });
  let rowlClass = "RowL";
  if (isLockedIn) rowlClass += " RowL-locked-in";
  return <div className={rowlClass}>{letterDivs}</div>;
}
