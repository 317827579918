import React, { useEffect, useState, useRef } from "react";
import { RowL, RowLState } from "./Row";
import dictionary from "./dictionary.json";
import { Clue, clue } from "./clue";
import { Keyboard } from "./Keyboard";
import common from "./common.json";
import { dictionarySet, pick, } from "./util";
import { urlParams, read, generate } from "./customgame";
import { exceptions } from "./exceptions";
import { BarChartLine, Gear, QuestionCircle, Share, People } from 'react-bootstrap-icons';
import Confetti from 'react-dom-confetti';
import { Alert, Button, Col, Container, Form, Modal, Row, OverlayTrigger, Popover, ProgressBar } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import useLocalStorage, { useOptions } from './useLocalStorage';
import { CharStatus } from './Share';
import LoseAd from './Ads';
import HintAd from './HintAd';
import WinAd from './WinAd';
import CustomGameAd from './CustomGameAd';
import Countdown from "./Countdown";
import Test from "./test"; 

export enum GameState {
  Playing,
  Won,
  Lost,
}

interface GameProps {
  maxGuesses: number;
}




/* CustomWord link */
function getCustomWordLink(target: string): string {
  return (
    window.location.origin +
    window.location.pathname +
    "?word=" +
    generate(target)
  );
}

let startCustomWord = "";
try {
  startCustomWord = read(urlParams("word") ?? "").toLowerCase();
} catch (e) {
  console.warn(e);
}
if (startCustomWord && !dictionarySet.has(startCustomWord)) {
  startCustomWord = "";
}
/*end custom game link */


export function Game(props: GameProps) {
  const [gameState, setGameState] = useState(GameState.Playing);
  const [guesses, setGuesses] = useState<string[]>([]);
  const [currentGuess, setCurrentGuess] = useState<string>("");
  const [customWord, setCustomWord] = useState<string>(startCustomWord);
  const [wordLength, setWordLength] = useState(
    customWord ? customWord.length : 5
  );


  const [endSlice, setEndSlice] = useState(20000)
  const [startSlice, setStartSlice] = useState(0)

  const targets = common
    .slice(startSlice, endSlice) // adjust for max target freakiness
    .filter((word) => dictionarySet.has(word) && !exceptions.has(word));

  function randomTarget(wordLength: number) {
    const eligible = targets.filter((word) => word.length === wordLength);
    return pick(eligible);
  }
  //custom game input
  const [target, setTarget] = useState(() => {
    return customWord || randomTarget(wordLength);
  });
  const gameID = generate(target);
  // end custom game input

  //keyboard configuration
  const [keyboardLayout, setKeyboardLayout] = useLocalStorage("qwertyuiop-asdfghjkl-EzxcvbnmB", "qwertyuiop-asdfghjkl-EzxcvbnmB");

  function HandleKeyboardLayout() {
    if (keyboardLayout == "qwertyuiop-asdfghjkl-BzxcvbnmE") {
      setKeyboardLayout("qwertyuiop-asdfghjkl-EzxcvbnmB")
    } else
      setKeyboardLayout("qwertyuiop-asdfghjkl-BzxcvbnmE")
  }

  //high contrast mode
  const [colorBlind, setColorBlind] = useOptions<boolean>("colorblind", false);


  const [active, setIsActive] = React.useState(false);

  //Feedback Message //
  const [winMessage, setWinMessage] = useState(false);
  const [loseMessage, setLoseMessage] = useState(false);
  const [shortMessage, setShortMessage] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(false);
  const [loseText, setLoseText] = useState('' + target + '.');
  const [wordWeirdnessMessage, setWordWeirdnessMessage] = useState(false);



  //handle lose message
  function handleLoseMessage() {
    setLoseMessage(false);
    setLoseText("");
  }

  useEffect(() => {
    const timer = () => setTimeout(() => setShortMessage(false), 2000);
    const timerId = timer();
    return () => {
      clearTimeout(timerId);
    };
  });

  useEffect(() => {
    const timer = () => setTimeout(() => setInvalidMessage(false), 2000);
    const timerId = timer();
    return () => {
      clearTimeout(timerId);
    };
  });

  useEffect(() => {
    const timer = () => setTimeout(() => setWordWeirdnessMessage(false), 2000);
    const timerId = timer();
    return () => {
      clearTimeout(timerId);
    };
  });
  //End Feedback Message //

  //Array of letters for hint
  var letterCount = [];
  for (var i = 0; i < target.length; i++)
    letterCount[i] = target[i].substring(0, 1);
  const [hintView, setHintView] = useState(false);
  function LetterList(props: any) {
    const characters = props.characters;
    const listItems = characters.map((character: any) =>
      <div key={character.toString()}>
        <div>{character}</div>
      </div>
    );
    var singleChar = characters[Math.floor(Math.random() * characters.length)];

    return (
      <div className={`hint-letter ${hintView ? "" : ''}` + (colorBlind ? " color-blind" : "")}>{singleChar}</div>
    );
  }
  const characters = letterCount;

  //Share //
  const getGuessStatuses = (guess: string): CharStatus[] => {
    const splitTarget = target.split('')
    const splitGuess = guess.split('')
    let elusive: string[] = [];

    const targetCharsTaken = splitTarget.map((_) => false)

    const statuses: CharStatus[] = Array.from(Array(guess.length))
    let j: number;
    // get correct guesses
    splitGuess.forEach((letter, i) => {
      if (target[i] === letter) {
        statuses[i] = 'correct'
        targetCharsTaken[i] = true
        return
      }
    })

    splitGuess.forEach((letter, i) => {
      if (statuses[i]) return

      if ((j = elusive.indexOf(letter)) > -1) {
        // find absent
        statuses[i] = 'absent'
        return
      }
      // now we are left with "present"s
      const indexOfPresentChar = splitTarget.findIndex(
        (x, index) => x === letter && !targetCharsTaken[index]
      )

      if (indexOfPresentChar > -1) {
        statuses[i] = 'present'
        targetCharsTaken[indexOfPresentChar] = true
        return
      } else {
        statuses[i] = 'absent'
        return
      }
    })

    return statuses
  }

  //emoji grid

  const generateEmojiGrid = (guesses: string[]) => {
    if (colorBlind === false) {
      return guesses
        .map((guess) => {
          const status = getGuessStatuses(guess)
          return guess
            .split('')
            .map((letter, i) => {
              switch (status[i]) {
                case 'correct':
                  return '🟩'
                case 'present':
                  return '🟨'
                default:
                  return '⬜'
              }
            })
            .join('')
        })
        .join('\n')
    } else {
      return guesses
        .map((guess) => {
          const status = getGuessStatuses(guess)
          return guess
            .split('')
            .map((letter, i) => {
              switch (status[i]) {
                case 'correct':
                  return '🟧'
                case 'present':
                  return '🟦'
                default:
                  return '⬜'
              }
            })
            .join('')
        })
        .join('\n')
    }
  }

  const shareStatus = (guesses: string[]) => {
    navigator.clipboard.writeText(
      'WordPlay Unlimited ' +
      guesses.length +
      '/6\n\n' +
      generateEmojiGrid(guesses) +
      '\n\n Think you can do better? Try the same puzzle: \n ' +
      getCustomWordLink(target)
    )
  }

  const shareCustomWord = () => {
    navigator.clipboard.writeText(
      'I challenge you to a Custom Word 👇\n' +
      getCustomWordLink(target)
    )
  }
  //End Share //


  //Stat Variables //
  const [gamesWon, setGamesWon] = useLocalStorage("won", 0);
  const [gamesLost, setGamesLost] = useLocalStorage("lost", 0);
  const [winStreak, setWinStreak] = useLocalStorage("streak", 0);
  const [maxWinStreak, setMaxWinStreak] = useLocalStorage("best streak", 0);

  const initialGuessDistribution = [
    {
      id: 0,
      name: "1",
      count: 0
    },
    {
      id: 1,
      name: "2",
      count: 0
    },
    {
      id: 2,
      name: "3",
      count: 0
    },
    {
      id: 3,
      name: "4",
      count: 0
    },
    {
      id: 4,
      name: "5",
      count: 0
    },
    {
      id: 5,
      name: "6",
      count: 0
    }
  ];

  const [guessDistribution, setGuessDistribution] = useLocalStorage("guess distribution", initialGuessDistribution);

  let sum = guessDistribution.reduce(function (prev, current) {
    return prev + +current.count
  }, 0);

  function distributionIncrease(guessDistributionId: any) {
    setGuessDistribution(
      guessDistribution.map((guessDistribution) => {
        if (guessDistribution.id === guessDistributionId) {
          return {
            ...guessDistribution,
            count: guessDistribution.count + 1
          };
        } else {
          return guessDistribution;
        }
      })
    );
  }

  //End stat variables //

  /*Stat functions */
  function handleMaxWinStreak() {
    if (maxWinStreak <= winStreak) {
      setMaxWinStreak(winStreak + 1)
    }
  }
  /*End Stat Functions */


  const reset = () => {
    setLoseMessage(false);
    setGuesses([]);
    setCurrentGuess("");
    setWinMessage(false);
    setShortMessage(false);
    setInvalidMessage(false);
    setTarget(randomTarget(wordLength));
    setGameState(GameState.Playing);
  };

  const onKey = (key: string) => {
    if (gameState !== GameState.Playing) {
      if (key === "Enter") {
        reset();
        setIsActive(false);
      }
      return;
    }
    if (guesses.length === props.maxGuesses) return;
    if (/^[a-z]$/.test(key)) {
      setCurrentGuess((guess) => (guess + key).slice(0, wordLength));
      setShortMessage(false);
      setInvalidMessage(false);
    } else if (key === "Backspace") {
      setCurrentGuess((guess) => guess.slice(0, -1));
      setShortMessage(false);
      setInvalidMessage(false);
    } else if (key === "Enter") {
      if (currentGuess.length !== wordLength) {
        setShortMessage(true);
        return;
      }
      if (!dictionary.includes(currentGuess)) {
        setInvalidMessage(true);
        return;
      }
      setGuesses((guesses) => guesses.concat([currentGuess]));
      setCurrentGuess((guess) => "");
      if (currentGuess === target) {
        setGameState(GameState.Won);
        setGamesWon(gamesWon + 1);
        setWinStreak(winStreak + 1);
        setWinMessage(true);
        //setShowWin(true);
        distributionIncrease(guesses.length);
        handleMaxWinStreak();
        setIsActive(true);
      } else if (guesses.length + 1 === props.maxGuesses) {
        setGameState(GameState.Lost);
        setGamesLost(gamesLost + 1);
        setWinStreak(0);
        setLoseMessage(true);
        setLoseText(target);
      } else {
        setShortMessage(false);
        setInvalidMessage(false);
      }
    }
  };

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!e.ctrlKey && !e.metaKey) {
        onKey(e.key);
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [currentGuess, gameState]);

  let letterInfo = new Map<string, Clue>();
  const rowDivs = Array(props.maxGuesses)
    .fill(undefined)
    .map((_, i) => {
      const guess = [...guesses, currentGuess][i] ?? "";
      const cluedLetters = clue(guess, target);
      const lockedIn = i < guesses.length;
      if (lockedIn) {
        for (const { clue, letter } of cluedLetters) {
          if (clue === undefined) break;
          const old = letterInfo.get(letter);
          if (old === undefined || clue > old) {
            letterInfo.set(letter, clue);
          }
        }
      }
      return (
        <RowL
          key={i}
          wordLength={wordLength}
          rowState={lockedIn ? RowLState.LockedIn : RowLState.Pending}
          cluedLetters={cluedLetters}
        />
      );
    });

  //custom CustomWord check
  const [customWordError, setCustomWordError] = useState('');

  function checkCustomWordValid(target: any) {
    if (dictionarySet.has(target)) {
      setCustomWordError("Copied to clipboard! 📋")
      return
    } else {
      setCustomWordError("😬 Not a valid word")
      console.log("invalid word")
    }
  }

  //Confetti Config //
  const config = {
    angle: 90,
    spread: 45,
    startVelocity: 45,
    elementCount: 300,
    decay: 0.9
  };

  //Modal Variables //
  const [showWin, setShowWin] = useState(false);
  const handleShowWin = () => setShowWin(true);
  const handleCloseWin = () => setShowWin(false);
  const [showCustomWord, setShowCustomWord] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const handleCloseCustomWord = () => setShowCustomWord(false);
  const handleCloseHint = () => setShowHint(false);
  const handleShowHint = () => setShowHint(true);
  const handleShowCustomWord = () => setShowCustomWord(true);
  const [showAbout, setShowAbout] = useState(false);
  const handleCloseAbout = () => setShowAbout(false);
  const handleShowAbout = () => setShowAbout(true);
  const [showStats, setShowStats] = useState(false);
  const handleCloseStats = () => setShowStats(false);
  const handleShowStats = () => setShowStats(true);
  const [showSettings, setShowSettings] = useState(false);
  const handleCloseSettings = () => setShowSettings(false);
  const handleShowSettings = () => setShowSettings(true);
  //End Modal Variables

  //hint
  function handleHint() {
    setShowHint(true);
    setShowSettings(false);
  }

  const HintAdSpace = () => {
    const [showElement, setShowElement] = React.useState(true);
    useEffect(() => {
      setTimeout(function () {
        setShowElement(false);
      }, 10000);
    }, []);

    return (
      <div>
        <div>
          {showElement ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                opacity: showElement ? 1 : 0
              }}
            >
              <Countdown />
              <div><HintAd /></div>
            </div>
          ) : (
            <div>
              <LetterList characters={characters} />
            </div>
          )}{" "}
        </div>
      </div>
    );
  };

  //Popovers //
  function handleShare() {
    shareStatus(guesses)
  }
  function handleCustomWordShare() {
    shareCustomWord();
  }
  const popover = (
    <Popover id="popover-share">
      <Popover.Body>
        Copied to clipboard! 📋
      </Popover.Body>
    </Popover>
  );
  const customWordPopover = (
    <Popover id="popover-share">

      <Popover.Body>
        {customWordError}
      </Popover.Body>
    </Popover>
  );
  const wordDifficultyPopover = (
    <Popover id="popover-share">
      <Popover.Body>
        Less = more common words <br />
        Normal = common and obscure words <br />
        More = more obscure words
      </Popover.Body>
    </Popover>
  );

  const wordDifficultyChangePopover = (
    <Popover id="popover-share">
      <Popover.Body>
        Word weirdness updated! Game reset.
      </Popover.Body>
    </Popover>
  );

  return (
    <div className={"Game" + (colorBlind ? " color-blind" : "")}>
      <div className="header-row header">
        <div className="header-flex one"><h1>WordPlay Unlimited</h1></div>
        <div className="header-flex two game-icons right-icons pointer stats-icon">
          <div onClick={handleShowStats}><BarChartLine size={18} /></div>
          <div className="settings-icon pointer" onClick={handleShowSettings}><Gear size={18} /></div>
        </div>

        <div className="header-flex three game-icons">
          <div className="about-icon game-icons pointer" onClick={handleShowAbout}><QuestionCircle size={18} /></div>
          <div className="game-icons custom-word-icon pointer" onClick={handleShowCustomWord}><People size={18} /></div>
        </div>
      </div>
      {rowDivs}

      <div className="confetti-loc">
        <Confetti
          active={active}
          config={config}
        />
      </div>

      <div className="feedback">
        <CSSTransition
          in={winMessage}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
          <Alert variant="success" onClose={() => setWinMessage(false)} dismissible>
            🎉Winner! <br />Press enter to play again
            <div className="mt-3">
              <OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose={true}>
                <Button variant="success" onClick={() => {
                  handleShare();
                }}><Share /> Share</Button>

              </OverlayTrigger>
            </div>
            <hr />

            <div className="mt-3 mb-3">
              <p>Start a <b>War</b>dle. Send this puzzle to a friend 🤯</p>
              <OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose={true}>
                <Button variant="success" onClick={() => {
                  handleCustomWordShare();
                }}><People /> Send to a friend</Button>

              </OverlayTrigger>
            </div>
            <div className="text-center">
              <WinAd />
            </div>
          </Alert>
        </CSSTransition>

        <CSSTransition
          in={loseMessage}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
          <Alert variant="danger" onClose={() => handleLoseMessage()} dismissible>
            😖 You lost! The word was <b>{loseText}</b>.<br />
            Press enter to play again
            <hr />
            <LoseAd />
          </Alert>
        </CSSTransition>

        <CSSTransition
          in={shortMessage}
          timeout={300}
          mountOnEnter={true}
          unmountOnExit={true}
          classNames="alert"
          onExited={() => setShortMessage(false)}
        >
          <Alert variant="warning" >
            😕 Too short of a word
          </Alert>
        </CSSTransition>

        <CSSTransition
          in={invalidMessage}
          timeout={300}
          classNames="alert"
          unmountOnExit
          onExited={() => setInvalidMessage(false)}
        >
          <Alert variant="warning" >
            😬 Not a valid word
          </Alert>
        </CSSTransition>

      </div>
      <Keyboard letterInfo={letterInfo} onKey={onKey} configuration={keyboardLayout} />
      <div className="text-center small game-id">
        Game ID: {gameID}
        <Test />
      </div>

      {/*MODALS ////////////// */}
      <Modal show={showWin} onHide={handleCloseWin}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="App-about">
            <div className="mb-5 mt-5 text-center" >
              <h2>🎉Winner!</h2>
              <p>Press enter to play again</p>
            </div>
            <Container>
              <Row className="mb-3 text-center">
                <Col><p>Share your results:</p>
                <OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose={true}>
                <Button variant="success" onClick={() => {
                  handleShare();
                }}><Share /> Share Results</Button>

              </OverlayTrigger>
              </Col>
              <Col><p>Send this puzzle to a friend</p>
                
                <OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose={true}>
                <Button variant="success" onClick={() => {
                  handleCustomWordShare();
                }}><People /> Send to a friend</Button>

              </OverlayTrigger>
              </Col>
              </Row>
              <Row>
               
              </Row>
            </Container>
            <div className="confetti-loc">
              <Confetti
                active={active}
                config={config}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseWin}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showHint} onHide={handleCloseHint}>
        <Modal.Header closeButton>
          <Modal.Title>Get a hint 👀</Modal.Title>
        </Modal.Header>
        <Modal.Body><Row className="App-about">
          <Col>
            <div className="mb-3">
              <p>A random letter from the word will appear below. You may or may not have already guessed the letter.</p>
              <div className="mt-3">
                <HintAdSpace />
              </div>
            </div>

          </Col>
        </Row></Modal.Body>
      </Modal>
      <Modal show={showCustomWord} onHide={handleCloseCustomWord}>
        <Modal.Header closeButton>
          <Modal.Title>🤯 Custom Word </Modal.Title>
        </Modal.Header>
        <Modal.Body><Row className="App-about">
          <Col>
            <p> Set a custom word and try to stump your friends.</p>
            <div className="mb-3">
              <p>Enter your word:</p>
              <div className="inline-flex">
                <input className="custom-word-input " type="text" placeholder="Enter your word" autoCapitalize='none' onChange={e => setTarget(e.target.value)} maxLength={11} />
                <OverlayTrigger trigger="click" placement="bottom" overlay={customWordPopover} rootClose={true}>
                  <Button variant="success" className="ml-1" onClick={() => {
                    checkCustomWordValid(target);
                    handleCustomWordShare();
                    reset();
                  }}>Copy link</Button>
                </OverlayTrigger>
              </div>
              <hr />
              <div className="mt-3">
                <p>Or generate a game with a random word:</p>
                <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose={true}>
                  <Button variant="success" onClick={() => {
                    getCustomWordLink(target);
                    handleCustomWordShare();
                    reset();
                  }}>Create random puzzle</Button>
                </OverlayTrigger>
              </div>
              <p className="small mt-3">After copying the link you can paste it anywhere. Creating a custom game will reset your current game.</p>
            </div>
            <hr />
            <div className="mt-2 text-center">
              <CustomGameAd />
            </div>
          </Col>
        </Row></Modal.Body>
      </Modal>
      <Modal show={showAbout} onHide={handleCloseAbout}>
        <Modal.Header closeButton>
          <Modal.Title>🎮 How to play:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className={"App-about" + (colorBlind ? " color-blind" : "")}>
            <Col>
              <p>
                You have 6 tries to guess the correct word.</p>
              <p>
                After each guess, you get feedback for letters that are in the word and/or in the word and in the right space:
              </p>
              <hr />
              <div className="Row-Left mt-3">
                <div className={"RowL-letter letter-correct" + (colorBlind ? " color-blind" : "")}>b</div>
                <div className="RowL-letter">r</div>
                <div className="RowL-letter">a</div>
                <div className="RowL-letter">k</div>
                <div className="RowL-letter">e</div>
              </div>
              <p><b>B</b> is in the target word and in the correct space!</p>

              <div className="Row-Left mt-3">
                <div className={"RowL-letter letter-elsewhere" + (colorBlind ? " color-blind" : "")}>c</div>
                <div className="RowL-letter">l</div>
                <div className="RowL-letter">u</div>
                <div className="RowL-letter">m</div>
                <div className="RowL-letter">p</div>
              </div>
              <p><b>C</b> is in the word but is not in the correct space.</p>
              <div className="Row-Left mt-3">
                <div className="RowL-letter letter-absent">b</div>
                <div className="RowL-letter">r</div>
                <div className="RowL-letter">a</div>
                <div className="RowL-letter">i</div>
                <div className="RowL-letter">n</div>
              </div>
              <p ><b>B</b> is not in the word.</p>
              <hr />
              <p>Not associated with the New York Times.</p>
              <p>Join our new <a href="https://www.facebook.com/groups/445625943928246">Facebook Group</a> to share results and challenge others!</p>
              <p className="small">Curious about a word's validity? Please check the <a href="https://scrabble.merriam.com/" target="_blank">Scrabble dictionary</a>.</p>
              <p className="small"><a href="https://app.termly.io/document/privacy-policy/82eb52b6-1d93-4556-bc85-72bdd7c8575b">Privacy policy</a> ·	<a href="mailto:wordleunlimited@gmail.com">Contact Us</a></p>

            </Col>
          </Row></Modal.Body>
      </Modal>
      <Modal show={showStats} onHide={handleCloseStats}>
        <Modal.Header closeButton>
          <Modal.Title>📊 Stats:</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="App-about">
          <div className="Game-stats">
            <div className="game-stat-item"><div>🎉</div><div><strong>Wins:</strong></div> {gamesWon}</div>
            <div className="game-stat-item"><div>😖</div><div><strong>Losses:</strong></div> {gamesLost}</div>
            <div className="game-stat-item"><div>🔥</div><div><strong>Streak:</strong></div> {winStreak}</div>
            <div className="game-stat-item"><div>🏆</div><div><strong>Max Streak:</strong></div>{maxWinStreak}</div>
          </div>
          <hr />
          <div>
            <h5>Guess Distribution:</h5>
            <Row>
              {guessDistribution.map((guessDistribution) => (
                <div className="guess-distr-cont" key={guessDistribution.id}>
                  <div className="guess-number">
                    {guessDistribution.name}
                  </div>
                  <div className="bar-wrap" id="bar-label">
                    <ProgressBar variant="success" label={guessDistribution.count} now={(guessDistribution.count / sum) * 100} />
                  </div>
                </div>
              ))}
            </Row>
          </div>
        </div></Modal.Body>
      </Modal>
      <Modal show={showSettings} onHide={handleCloseSettings}>
        <Modal.Header closeButton>
          <Modal.Title>⚙️ Settings:</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="Game-options">
          <Row className="mb-3">
            <Col className="text-start" ># of Letters: ({wordLength})</Col>
            <Col className="text-end">
              <Form.Range aria-label="Number of letter" id="wordLength"
                className="danger"
                disabled={guesses.length > 0 || currentGuess !== ""}
                value={wordLength}
                min="4"
                max="11"
                onChange={(e) => {
                  const length = Number(e.target.value);
                  setTarget(randomTarget(length));
                  setWordLength(length);
                }}
              >
              </Form.Range>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="text-start" >High contrast:</Col>
            <Col className="text-end">
              <Form.Check
                type="switch"
                id="keyboard-config-switch"
                onChange={() => setColorBlind((x: boolean) => !x)}
                checked={colorBlind}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="text-start" >Enter on right:</Col>
            <Col className="text-end">
              <Form.Check
                type="switch"
                id="keyboard-config-switch"
                onChange={() => HandleKeyboardLayout()}
                checked={keyboardLayout === "qwertyuiop-asdfghjkl-BzxcvbnmE" ? true : false}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col className="text-start" >

              <OverlayTrigger trigger="click" placement="bottom" overlay={wordDifficultyPopover} rootClose={true}>
                <div className="" onClick={() => {
                }}>Word Weirdness <QuestionCircle /></div>
              </OverlayTrigger>
            </Col>
            <Col>
              <div className="mb-3  text-end">
                <Col>
                  <span>Less Weird </span>
                  <Form.Check
                    className="mr-0"
                    inline
                    name="word difficulty"
                    type='radio'
                    id="easy"
                    onChange={() => { setEndSlice(2000); setStartSlice(0); reset(); setWordWeirdnessMessage(true); }}
                    disabled={guesses.length > 0 || currentGuess !== ""}
                    checked={endSlice === 2000 ? true : false}
                  />
                </Col>
                <Col>
                  <span>Normal </span>
                  <Form.Check
                    className="mr-0"
                    inline
                    name="word difficulty"
                    type='radio'
                    id="normal"
                    disabled={guesses.length > 0 || currentGuess !== ""}
                    onChange={() => { setEndSlice(20000); setStartSlice(0); reset(); setWordWeirdnessMessage(true); }}
                    checked={endSlice === 20000 ? true : false}
                  />
                </Col>
                <Col>
                  <span>More Weird </span>
                  <Form.Check
                    className="mr-0"
                    inline
                    name="word difficulty"
                    type='radio'
                    id="hard"
                    disabled={guesses.length > 0 || currentGuess !== ""}
                    onChange={() => { setEndSlice(2000000); setStartSlice(20000); reset(); setWordWeirdnessMessage(true); }}
                    checked={endSlice === 2000000 ? true : false}
                  />
                </Col>
              </div>
            </Col>
          </Row>
          <Row className="text-center">
            <CSSTransition
              in={wordWeirdnessMessage}
              timeout={300}
              mountOnEnter={true}
              unmountOnExit={true}
              classNames="alert top-zindex"
              onExited={() => setWordWeirdnessMessage(false)}
            >
              <Alert variant="success" >
                ⚙️ Word weirdness updated! <br />Game reset.
              </Alert>
            </CSSTransition>
          </Row>
          <Row className="mb-5">
            <Col className="text-start" >Stuck on a word?</Col>
            <Col className="text-end">
              <Button onClick={handleHint} size="sm">Get a hint 👀</Button>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <Button
                variant="danger"
                style={{ flex: "0" }}
                disabled={gameState !== GameState.Playing || guesses.length === 0}
                onClick={() => {
                  setGameState(GameState.Lost);
                  setGamesLost(gamesLost + 1);
                  setWinStreak(0);
                  setLoseMessage(true);
                  setLoseText(target);
                  setShowSettings(false);
                }}
              >
                🏳️ Quit Game
              </Button>
            </Col>
          </Row>

        </div>
        </Modal.Body>
      </Modal>


    </div>
  );
}
export default Game;
