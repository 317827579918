import React from 'react';

export default class MobileAd extends React.Component {
  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
        <ins className='adsbygoogle'
          style={{ display: "inline-block", width: "300px", height: "50px" }}
          data-ad-client='ca-pub-8227920830433579'
          data-ad-slot='4873158357'
        />
    );
  }
}
