
  
  export function urlParams(parameter: string): string | null {
    return new URLSearchParams(window.location.search).get(parameter);
  }


  export function generate(text: string): string {
    return window
      .btoa(text)
      .replace(/=*$/, "");
  }
  
  export function read(text: string): string {
    return window.atob(text.replace(/_/g, "/").replace(/-/g, "+"));
  }

  
  export{}